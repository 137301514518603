const styles = {
  root: {},
  menuContainer: {
    position: 'fixed',
    boxShadow: ({ shadows }) => shadows[3],
    left: 0,
    right: 0
  },
  menuItems: {
    padding: ({ spacing }) => spacing(2, 2, 3, 2),
    marginTop: ({ spacing }) => spacing(1),
    backgroundColor: ({ palette }) => palette.background.default
  }
};

export default styles;
