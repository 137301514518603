import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import Icon from 'components/Icon';

import styles from './styles';

const Component = ({ icon, text }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box
      component="div"
      sx={{ ...styles.root, ...(isDesktop ? styles.rootDesktop : {}) }}
    >
      <Icon icon={icon} size="2x" color="primary" />
      <Typography
        component="span"
        variant="subtitle1"
        sx={{ ...styles.text, ...(isDesktop ? styles.textDesktop : {}) }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Component;
