const getSmallHeight = ({ spacing }) => spacing(4);
const getHeight = ({ spacing }) => spacing(8);
const getBigHeight = ({ spacing }) => spacing(16);

const styles = {
  root: {
    height: getHeight,
    padding: ({ spacing }) => spacing(0, 2),
    boxShadow: 'none',
    justifyContent: 'center',
    transition: ({ transitions }) =>
      transitions.create(['background-color', 'height', 'box-shadow']),
    backgroundColor: '#00000000',
    backgroundImage: 'none'
  },
  rootMenuVisible: {
    backgroundColor: ({ palette }) => palette.background.default,
    boxShadow: ({ shadows }) => shadows[3]
  },
  rootScrolled: {
    backgroundColor: ({ palette }) => palette.background.default,
    boxShadow: ({ shadows }) => shadows[3],
    height: getHeight
  },
  rootDesktop: {
    height: getBigHeight,
    padding: ({ spacing }) => spacing(0, 6)
  },
  container: {
    position: 'relative'
  },
  logo: {
    verticalAlign: 'middle',
    width: ({ spacing }) => spacing(10),
    transition: ({ transitions }) => transitions.create('width')
  },
  bigLogo: {
    width: ({ spacing }) => spacing(20)
  }
};

export default styles;

export { getSmallHeight, getHeight, getBigHeight };
