import React, { useMemo } from 'react';
import { Container, Box } from '@mui/material';

import Title from './Title';

import styles from './styles';

const Component = ({
  id,
  title = '',
  sx = {},
  color = '',
  children,
  ...rest
}) => {
  const colorTitle = useMemo(() => {
    let newColor;
    switch (color) {
      case '':
        newColor = 'primary';
        break;
      case 'text':
        newColor = 'secondary';
        break;
      default:
        newColor = '';
    }

    return newColor;
  }, [color]);

  return (
    <Box
      component="section"
      id={id}
      sx={{
        ...styles.root,
        ...(color === 'primary' ? styles.primary : {}),
        ...(color === 'text' ? styles.text : {}),
        ...sx
      }}
      {...rest}
    >
      <Container>
        {title !== '' && <Title color={colorTitle}>{title}</Title>}
        <Box component="div" sx={styles.container}>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default Component;
