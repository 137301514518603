import colors from './colors';

const options = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          borderColor: colors.text.primary,
          color: colors.text.primary,
          borderWidth: 3,
          borderStyle: 'solid',
          '&:hover': {
            borderWidth: 3,
            borderColor: colors.primary.main,
            color: colors.background.default,
            backgroundColor: colors.primary.main
          }
        }
      },
      {
        props: { color: 'primary' },
        style: {
          borderColor: colors.primary.main,
          '&:hover': {
            backgroundColor: colors.primary.main
          }
        }
      },
      {
        props: { size: 'xlarge' },
        style: {
          padding: '16px 32px'
        }
      }
    ]
  },
  MuiLink: {
    defaultProps: {
      color: colors.text.primary,
      underline: 'none'
    }
  },
  MuiDivider: {
    defaultProps: {
      style: {}
    }
  }
};

export default options;
