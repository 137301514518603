import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';

import Section from 'components/Section';

import styles from './styles';

const Component = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const text = isDesktop ? 'Agende já o seu evento' : 'Agende já';
  return (
    <Section
      id=""
      sx={{ ...styles.root, ...(isDesktop ? styles.rootDesktop : {}) }}
    >
      <Box
        component="div"
        sx={styles.container}
        onClick={() => {
          window.location.href =
            'mailto:ostraslounge@gmail.com?subject=Novo Agendamento';
        }}
      >
        <Typography sx={styles.text} variant="h4">
          {text}
        </Typography>
      </Box>
    </Section>
  );
};

export default Component;
