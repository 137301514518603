import { useState } from 'react';
import { AppBar, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import useScroll from '@react-hook/window-scroll';
import Link from 'components/Link';

import Menu from './Menu';
import Items from './Items';
import styles from './styles';

const Component = ({ logo, language, onLanguage, items, languageItems }) => {
  const scrollY = useScroll();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [menuVisible, setMenuVisible] = useState(false);

  const isScrolling = scrollY > 50;

  return (
    <AppBar
      position="fixed"
      sx={{
        ...styles.root,
        ...(isDesktop ? styles.rootDesktop : {}),
        ...(isScrolling ? styles.rootScrolled : {}),
        ...(menuVisible ? styles.rootMenuVisible : {})
      }}
    >
      <Grid
        container
        sx={styles.container}
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        {logo && (
          <Grid item>
            <Link variantCheck="image" href="#">
              <Box
                component="img"
                alt="logo"
                src={logo}
                sx={{
                  ...styles.logo,
                  ...(!isScrolling && isDesktop ? styles.bigLogo : {})
                }}
              />
            </Link>
          </Grid>
        )}
        <Grid item>
          {isDesktop ? (
            <Items
              language={language}
              onLanguage={onLanguage}
              items={items}
              languageItems={languageItems}
            />
          ) : (
            <Menu
              language={language}
              onLanguage={onLanguage}
              items={items}
              languageItems={languageItems}
              open={menuVisible}
              onToggle={() => setMenuVisible(!menuVisible)}
            />
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Component;
