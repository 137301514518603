import React from 'react';
import { Box } from '@mui/material';

import Title from 'components/Title';

import styles from './styles';

const Component = ({ label, children }) => {
  return (
    <Box component="div">
      <Title sx={styles.title}>{label}</Title>
      {children}
    </Box>
  );
};

export default Component;
