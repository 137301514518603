import { useTheme, useMediaQuery } from '@mui/material';

import Toggle from 'components/Toggle';

const Component = ({ value, onChange, items, sx }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Toggle
      value={value}
      onChange={onChange}
      items={
        isDesktop
          ? items.map(({ id, href }) => ({ id, label: id, href }))
          : items
      }
      sx={sx}
    />
  );
};

export default Component;
