import { Link } from '@mui/material';

import styles from './styles';

const Component = ({
  variantCheck = 'standard',
  fullWidth = false,
  sx = {},
  ...rest
}) => {
  return (
    <Link
      variant="button"
      sx={{
        ...(variantCheck === 'standard' ? styles.standard : {}),
        ...(fullWidth ? styles.fullWidth : {}),
        ...sx
      }}
      {...rest}
    />
  );
};

export default Component;
