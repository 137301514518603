import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMobileAlt,
  faMapMarkerAlt,
  faBars,
  faTimes,
  faChevronDown,
  faChevronUp,
  faQuoteRight,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons';
import { faSmileBeam, faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

const register = () =>
  library.add(
    faSmileBeam,
    faMobileAlt,
    faClock,
    faMapMarkerAlt,
    faFacebookF,
    faTwitter,
    faInstagram,
    faBars,
    faTimes,
    faChevronUp,
    faChevronDown,
    faQuoteLeft,
    faQuoteRight
  );

export default register;
