import backgroundImage from './resources/background.jpg';

const styles = {
  root: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      '&::before': {
        backgroundColor: ({ palette }) => palette.primary.main,
        transform: 'scale(1.07)',
        opacity: 0.65
      }
    },
    '&::before': {
      transition: ({ transitions }) =>
        transitions.create(['background-color', 'transform', 'opacity']),
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      opacity: 0.5,
      transform: 'scale(1)',
      transformOrigin: 'center'
    }
  },
  rootDesktop: {
    height: ({ spacing }) => spacing(20)
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  text: {
    textTransform: 'uppercase',
    textAlign: 'center'
  }
};

export default styles;
