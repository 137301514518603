import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import colors from './colors';
import typography from './typography';
import components from './components';
import registerIcons from './icons';

const options = {
  palette: {
    mode: 'dark',
    ...colors
  },
  typography,
  shape: {
    borderRadius: 0
  },
  components
};

export default responsiveFontSizes(createTheme(options));
export { registerIcons };
