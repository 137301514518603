import { Grid } from '@mui/material';

import Section from 'components/Section';

import Item from './components/Item';

import styles from './styles';

const Component = () => {
  return (
    <Section id="reviews" title="Avaliações" sx={styles.root}>
      <Grid container gap={4} alignItems="center" justifyContent="center">
        <Grid item>
          <Item text="Que rico serviço, e que belas ostras 💙😉" />
        </Grid>
        <Grid item>
          <Item text="Ha coisas que nao se explicam.. sentem se… esta é uma delas… Top!!!" />
        </Grid>
        <Grid item>
          <Item text="Sempre com uma presença marcante e distinta este amigo 😃💪💯" />
        </Grid>
        <Grid item>
          <Item text="Muito boas as ostras, experimentei no kimoa bar e são super frescas/saborosas. Recomendo!" />
        </Grid>
        <Grid item>
          <Item text="O Ostras Lounge já está na ponta da minha língua!🙌" />
        </Grid>
        <Grid item>
          <Item text="Poucas coisas batem o prazer de uma ostra fresca. Votos de sucesso" />
        </Grid>
        <Grid item>
          <Item text="As vossas ostras são realmente muito boas e de excelente qualidade. Parabéns!" />
        </Grid>
      </Grid>
    </Section>
  );
};

export default Component;
