import { Box, Typography } from '@mui/material';

const Component = () => {
  return (
    <Box component="div">
      <Typography>Por Marcação</Typography>
    </Box>
  );
};

export default Component;
