const styles = {
  root: {
    textTransform: 'uppercase',
    marginBottom: '1.25em',
    '&::first-letter': {
      color: ({ palette }) => palette.primary.main
    }
  },
  secondary: {
    '&::first-letter': {
      color: ({ palette }) => palette.secondary.main
    }
  }
};

export default styles;
