const styles = {
  root: {
    padding: ({ spacing }) => spacing(6)
  },
  primary: {
    backgroundColor: ({ palette }) => palette.primary.main,
    color: ({ palette }) => palette.background.default
  },
  text: {
    backgroundColor: ({ palette }) => palette.text.primary,
    color: ({ palette }) => palette.background.default
  },
  container: {}
};

export default styles;
