import { getBigHeight, getHeight } from 'components/Header/styles';

const styles = {
  root: {
    textAlign: 'center'
  },
  rootDesktop: {
    textAlign: 'left'
  },
  main: {
    marginTop: getHeight
  },
  mainDesktop: {
    marginTop: getBigHeight
  },
};

export default styles;
