import { Box, Grid } from '@mui/material';

import Icon from 'components/Icon';
import Link from 'components/Link';

const Component = () => {
  return (
    <Box component="div">
      <Grid container gap={4}>
        <Grid item>
          <Link href="https://www.facebook.com/ostraslounge/">
            <Icon icon={['fab', 'facebook-f']} size="2x" />
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.instagram.com/ostraslounge/">
            <Icon icon={['fab', 'instagram']} size="2x" />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Component;
