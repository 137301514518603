const styles = {
  root: {
    padding: ({ spacing }) => spacing(4),
    color: ({ palette }) => palette.background.default,
    backgroundColor: ({ palette }) => palette.text.primary,
    borderRadius: 2
  },
  rootDesktop: {
    maxWidth: ({ spacing }) => spacing(60)
  },
  icon: {
    display: 'inline-block',
    color: ({ palette }) => palette.secondary.main
  },
  text: {
    wordBreak: 'break-word'
  }
};

export default styles;
