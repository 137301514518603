import React from 'react';
import { Typography } from '@mui/material';

import styles from './styles';

const Component = ({ color = '', variant = 'h3', children, sx = {} }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        ...styles.root,
        ...(color === 'secondary' ? styles.secondary : {}),
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default Component;
