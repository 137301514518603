import { Grid, useTheme, useMediaQuery } from '@mui/material';

import TextIcon from 'components/TextIcon';
import Section from 'components/Section';

import styles from './styles';

const Component = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Section id="overview" color="primary" sx={styles.root}>
      <Grid
        container
        gap={4}
        justifyContent={isDesktop ? 'space-between' : 'center'}
      >
        <Grid item xs={12} md="auto">
          <TextIcon text="Satisfação 100%" icon={['far', 'smile-beam']} />
        </Grid>
        <Grid item xs={12} md="auto">
          <TextIcon text="Setúbal" icon="map-marker-alt" />
        </Grid>
        <Grid item xs={12} md="auto">
          <TextIcon text="Por Marcação" icon={['far', 'clock']} />
        </Grid>
      </Grid>
    </Section>
  );
};

export default Component;
