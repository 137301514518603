const styles = {
  standard: {
    padding: ({ spacing }) => spacing(1, 2),
    position: 'relative',
    transition: ({ transitions }) => transitions.create('color'),
    '&::after': {
      content: '""',
      overflow: 'hidden',
      height: 2,
      backgroundColor: ({ palette }) => palette.primary.main,
      display: 'inline-block',
      position: 'absolute',
      left: ({ spacing }) => spacing(2),
      right: ({ spacing }) => spacing(2),
      bottom: 0,
      transition: ({ transitions }) => transitions.create('transform'),
      transform: 'scaleX(0)',
      transformOrigin: 'left'
    },
    '&:hover': {
      color: ({ palette }) => palette.primary.main,
      '&::after': {
        transform: 'scaleX(1)'
      }
    }
  },
  fullWidth: {
    display: 'block'
  }
};

export default styles;
