import { getHeight, getBigHeight } from 'components/Header/styles';
// import landingImage from './resources/landing.jpg';
import landingImage from './resources/landing_cropped.jpg';

const styles = {
  root: {
    padding: ({ spacing }) => spacing(8, 0, 8, 0),
    minHeight: theme => `calc(100vh - ${getHeight(theme)})`,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundImage: `url(${landingImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      opacity: 0.5,
      zIndex: -1
    }
  },
  rootDesktop: {
    padding: ({ spacing }) => spacing(35, 0, 0, 0),
    minHeight: theme => `calc(100vh - ${getBigHeight(theme)})`
  },
  buttonsContainer: {
    marginTop: ({ spacing }) => spacing(6)
  }
};

export default styles;
