import React from 'react';
import { Link, Typography, Grid, Box, Container, useTheme, useMediaQuery } from '@mui/material';

import Title from 'components/Title';
import Item from './Item';

import styles from './styles';

const Component = ({ id = 'footer', items = [] }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box component="footer" sx={styles.root} id={id}>
      <Container>
        <Grid
          container
          gap={12}
          justifyContent={isDesktop ? 'space-between' : 'center'}
        >
          {items.map(({ label, children }) => (
            <Grid item key={`item-${label}`}>
              <Item label={label}>{children}</Item>
            </Grid>
          ))}
        </Grid>
      </Container>
        <Box sx={{ ...styles.main, ...(isDesktop ? styles.footerDesktop : {})} }>
          <Typography textAlign='center' paragraph={true}>© Copyright 2024 WeBro. Todos os direitos reservados.</Typography>
          <Grid container wrap='nowrap' spacing={1} sx={{...(isDesktop ? styles.footerBrandDesktop : styles.footerBrand)}}>
            <Grid item>
              <Title variant='inherit' sx={{...styles.footerTitle}}>Desenvolvido por </Title>
            </Grid>
            <Grid item>
              <Link sx={styles.footerLink} href="https://webro-project.web.app/" fullWidth> WeBro Inc.</Link>
            </Grid>
          </Grid>
        </Box>
    </Box>
  );
};

export default Component;
