const styles = {
  root: {},
  events: {
    marginBottom: ({ spacing }) => spacing(5)
  },
  eventsDesktop: {
    marginBottom: ({ spacing }) => spacing(10)
  },
  divider: {
    borderWidth: 1,
    borderColor: ({ palette }) => palette.secondary.main,
    marginBottom: ({ spacing }) => spacing(5)
  }
};

export default styles;
