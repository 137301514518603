const styles = {
  root: {
    position: 'relative',
    width: ({ spacing }) => spacing(3),
    height: ({ spacing }) => spacing(3)
  },
  icon: {
    transition: ({ transitions }) => transitions.create('transform'),
    transform: 'scale(1)'
  },
  onIcon: {
    transform: 'scale(0)'
  },
  onIconShow: {
    transform: 'scale(1)'
  },
  offIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    transform: 'scale(0)'
  },
  offIconShow: {
    transform: 'scale(1)'
  }
};

export default styles;
