import {
  Box,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme
} from '@mui/material';

import pic1Image from './resources/pic1.jpg';
import pic2Image from './resources/pic2.jpg';
import pic3Image from './resources/pic3.jpg';
import pic4Image from './resources/pic4.jpg';
import pic5Image from './resources/pic5.jpg';
import pic6Image from './resources/pic6.jpg';
import pic7Image from './resources/pic7.jpg';
import pic8Image from './resources/pic8.jpg';
import pic9Image from './resources/pic9.JPG';
import pic10Image from './resources/pic10.jpg';
import pic11Image from './resources/pic11.JPG';
import pic12Image from './resources/pic12.jpg';
import pic13Image from './resources/pic13.jpg';
import pic14Image from './resources/pic14.JPG';
import pic15Image from './resources/pic15.jpg';
import pic16Image from './resources/pic16.jpg';
import pic17Image from './resources/pic17.jpeg';
import pic18Image from './resources/pic18.jpeg';
import pic19Image from './resources/pic19.jpeg';
import pic20Image from './resources/pic20.jpeg';
import pic21Image from './resources/pic21.jpeg';
import pic22Image from './resources/pic22.jpeg';
import pic23Image from './resources/pic23.jpeg';
import pic24Image from './resources/pic24.jpeg';

import styles from './styles';

const images = [
  pic17Image,
  pic18Image,
  pic19Image,
  pic20Image,
  pic21Image,
  pic22Image,
  pic23Image,
  pic24Image
];

const Component = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <section>
      <ImageList cols={isDesktop ? 4 : 1} gap={0} sx={styles.list}>
        {images.map((img, index) => (
          <ImageListItem key={`item-${index}`} xs={3}>
            <Box component="img" src={img} width="100%" height="450px" />
          </ImageListItem>
        ))}
      </ImageList>
    </section>
  );
};

export default Component;
