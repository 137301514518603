import { getSmallHeight } from 'components/Header/styles';
const styles = {
  root: {
    padding: ({ spacing }) => spacing(8, 5)
  },
  main: {
    marginTop: getSmallHeight,
  },
  footerDesktop: {
    textAlign : 'right',
    paddingRight : '20px',
    paddingBottom: '10px',
  },
  footerTitle: {
    textTransform: 'lowercase',
    marginBottom: '1.25em',
    '&::first-letter': {
      color: ({ palette }) => palette.primary.main,
      textTransform: 'uppercase',
    },
  },
  footerLink: {
    position: 'relative',
    transition: ({ transitions }) => transitions.create('color'),
    '&::after': {
      content: '""',
      overflow: 'hidden',
      height: 2,
      backgroundColor: ({ palette }) => palette.primary.main,
      display: 'inline-block',
      position: 'absolute',
      bottom: 0,
      transition: ({ transitions }) => transitions.create('transform'),
      transform: 'scaleX(0)',
      transformOrigin: 'left'
    },
    '&:hover': {
      color: ({ palette }) => palette.primary.main,
      '&::after': {
        transform: 'scaleX(1)'
      }
    }
  },
  footerBrandDesktop: {
    justifyContent: 'flex-end',
  },
  footerBrand: {
    justifyContent: 'center',
  }
};

export default styles;
