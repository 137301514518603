import { Grid, Link, Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Home from 'pages/Home';

import Contacts from './components/Contacts';
import FollowUs from './components/FollowUs';
import Schedule from './components/Schedule';

import logo from './resources/logo.svg';
import styles from './styles';

const Container = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box
      component="div"
      sx={{ ...styles.root, ...(isDesktop ? styles.rootDesktop : {}) }}
    >
      <Header
        logo={logo}
        items={[
          {
            label: 'Avaliações',
            href: '#reviews'
          },
          {
            label: 'Sobre',
            href: '#about'
          },
          {
            label: 'Serviços',
            href: '#services'
          },
          {
            label: 'Contactos',
            href: '#contacts'
          }
        ]}
      />
      <Box
        component="main"
        sx={{ ...styles.main, ...(isDesktop ? styles.mainDesktop : {}) }}
      >
        <Home />
      </Box>
      <Footer
        id="contacts"
        items={[
          { label: 'Contacto', children: <Contacts /> },
          { label: 'Horário', children: <Schedule /> },
          { label: 'Segue-nos', children: <FollowUs /> }
        ]}
      />
    </Box>
  );
};

export default Container;
