import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import Title from 'components/Title';

import styles from './styles';

const Component = ({ image, title, children, color, reverse = false, sx }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box component="div" sx={sx}>
      <Grid
        container
        gap={8}
        wrap="nowrap"
        direction={reverse ? 'row-reverse' : 'row'}
      >
        {isDesktop && (
          <Grid item xs={6}>
            <Box
              component="img"
              sx={styles.image}
              alt="side-pic"
              src={image}
              width="100%"
              maxHeight="700px"
            />
          </Grid>
        )}
        <Grid item xs={isDesktop ? 6 : 12}>
          <Title color={color} variant="h4">
            {title}
          </Title>
          <Box component="div">{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Component;
