import React, { Fragment } from 'react';
import { Grid, Divider, Box } from '@mui/material';

import Link from 'components/Link';

import styles from './styles';

const Component = ({ value, onChange = () => {}, items = [], sx }) => {
  return (
    <Box component="div" sx={sx}>
      <Grid container justifyContent="space-around">
        {items.map(({ id, label, href }, index) => (
          <Fragment key={`item-${id}`}>
            <Grid item>
              <Link
                sx={{
                  ...styles.item,
                  ...(value === id ? styles.selectedItem : {})
                }}
                onClick={() => onChange(id)}
                href={href}
              >
                {label}
              </Link>
            </Grid>
            {index < items.length - 1 && (
              <Grid item>
                <Divider orientation="vertical" sx={styles.divider} />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default Component;
