import { Box, IconButton, Collapse } from '@mui/material';

import ToggleIcon from 'components/ToggleIcon';
import Icon from 'components/Icon';
import Items from 'components/Header/Items';

import styles from './styles';

const Component = ({
  language,
  onLanguage,
  items,
  languageItems,
  onToggle,
  open = false
}) => {
  return (
    <Box component="div" sx={styles.root}>
      <IconButton onClick={onToggle}>
        <ToggleIcon
          on={open}
          onIcon={<Icon icon={['fas', 'times']} />}
          offIcon={<Icon icon={['fas', 'bars']} />}
        />
      </IconButton>
      <Box component="div" sx={styles.menuContainer}>
        <Collapse in={open}>
          <Box component="div" sx={styles.menuItems}>
            <Items
              language={language}
              onLanguage={onLanguage}
              items={items}
              languageItems={languageItems}
            />
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default Component;
