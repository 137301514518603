const options = {
  primary: {
    main: '#fdcd69'
  },
  secondary: {
    main: '#1781aa'
  },
  background: {
    default: '#0d1a1f',
    paper: '#232f35'
  },
  text: {
    primary: '#fafafa'
  },
  divider: '#fafafa'
};

export default options;
