import { Box, Typography } from '@mui/material';

const Component = () => {
  return (
    <Box component="div">
      <Typography>ostraslounge@gmail.com</Typography>
      <br />
      <Typography>Setúbal</Typography>
    </Box>
  );
};

export default Component;
