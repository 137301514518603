import React from 'react';
import { Divider, Box } from '@mui/material';

import Title from 'components/Title';

import styles from './styles';

const Component = ({ color = '', children }) => {
  return (
    <Box component="div" sx={styles.root}>
      <Title color={color} sx={styles.text}>
        {children}
      </Title>
      <Divider
        orientation="horizontal"
        sx={{
          ...styles.divider,
          ...(color === 'primary' ? styles.dividerPrimary : {}),
          ...(color === 'secondary' ? styles.dividerSecondary : {})
        }}
      />
    </Box>
  );
};

export default Component;
