import {
  Paper,
  Typography,
  Grid,
  useTheme,
  useMediaQuery
} from '@mui/material';

import Icon from 'components/Icon';

import styles from './styles';

const Component = ({ text }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Paper sx={{ ...styles.root, ...(isDesktop ? styles.rootDesktop : {}) }}>
      <Grid container wrap="nowrap" gap={2}>
        <Grid item>
          <Icon icon={['fas', 'quote-left']} sx={styles.icon} />
        </Grid>
        <Grid item>
          <Typography component="span" variant="body1" sx={styles.text}>
            {text}
          </Typography>
        </Grid>
        <Grid item alignSelf="flex-end">
          <Icon icon={['fas', 'quote-right']} sx={styles.icon} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Component;
