const styles = {
  item: {
    fontWeight: ({ typography }) => typography.fontWeightRegular
  },
  selectedItem: {
    fontWeight: ({ typography }) => typography.fontWeightBold,
    color: ({ palette }) => palette.primary.main
  },
  divider: {
    display: 'inline-block',
    height: ({ spacing }) => spacing(2),
    verticalAlign: 'middle'
  }
};

export default styles;
