const styles = {
  root: {
    textAlign: 'center',
    marginBottom: ({ spacing }) => spacing(8)
  },
  text: {
    marginBottom: ({ spacing }) => spacing(2)
  },
  divider: {
    margin: '0 auto',
    width: ({ spacing }) => spacing(12),
    borderWidth: ({ spacing }) => spacing(0.25)
  },
  dividerPrimary: {
    borderColor: ({ palette }) => palette.primary.main
  },
  dividerSecondary: {
    borderColor: ({ palette }) => palette.secondary.main
  }
};

export default styles;
