import {
  Box,
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';

import Section from 'components/Section';
import styles from './styles';

const Component = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Section
      id=""
      sx={{ ...styles.root, ...(isDesktop ? styles.rootDesktop : {}) }}
    >
      <Box component="div">
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          textAlign="center"
          color="primary"
        >
          ABERTURA DE OSTRAS AO NATURAL NO MOMENTO
        </Typography>
        <Typography gutterBottom variant="h1" textAlign="center">
          Ostras de Qualidade Superior
        </Typography>
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          textAlign="center"
          color="primary"
        >
          EVENTOS | FESTAS
        </Typography>
        <Grid
          container
          gap={isDesktop ? 4 : 2}
          justifyContent="center"
          sx={styles.buttonsContainer}
        >
          <Grid item xs={12} md="auto">
            <Button
              color="primary"
              variant="outlined"
              size="xlarge"
              fullWidth={!isDesktop}
              onClick={() => {
                window.location.href =
                  'mailto:ostraslounge@gmail.com?subject=Novo Agendamento';
              }}
            >
              Agendar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

export default Component;
