import { Grid, Button, useMediaQuery, useTheme } from '@mui/material';
import Link from 'components/Link';
import Translation from 'components/Translation';

const Component = ({
  language,
  onLanguage,
  items = [],
  languageItems = []
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container gap={2} alignItems="center">
      {items.map(({ label, href }) => (
        <Grid item key={`item-${label}`} xs={12} md="auto">
          <Link href={href} fullWidth>
            {label}
          </Link>
        </Grid>
      ))}
      <Grid item xs={12} md="auto">
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            window.location.href =
              'mailto:ostraslounge@gmail.com?subject=Novo Agendamento';
          }}
        >
          Agendar Online
        </Button>
      </Grid>
      {language && (
        <Grid item xs={12} md="auto">
          <Translation
            items={languageItems}
            value={language}
            onChange={onLanguage}
            variant={isDesktop ? 'toggle' : undefined}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Component;
