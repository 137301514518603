const options = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  body1: {
    lineHeight: 1.85,
    letterSpacing: '0.1em'
  },
  h1: {
    fontSize: '2.75rem',
    fontWeight: 600,
    letterSpacing: '0.1em'
  },
  h2: {
    fontSize: '2.5rem',
    fontWeight: 600,
    letterSpacing: '0.05em'
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '0.1em'
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 600,
    letterSpacing: '0.075em'
  },
  h5: {
    fontSize: '1.5rem',
    letterSpacing: '0.075em'
  },
  h6: {
    fontSize: '1.25rem',
    letterSpacing: '0.075em'
  },
  subtitle1: {
    letterSpacing: '0.075em'
  },
  button: {
    letterSpacing: '0.075em',
    fontWeight: 600
  }
};

export default options;
