const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  rootDesktop: {
    flexDirection: 'row'
  },
  text: {
    marginTop: ({ spacing }) => spacing(2)
  },
  textDesktop: {
    marginTop: 0,
    marginLeft: ({ spacing }) => spacing(2)
  }
};

export default styles;
