import { Typography, useTheme, useMediaQuery, Divider } from '@mui/material';
import Section from 'components/Section';
import SideBySide from 'components/SideBySide';

import historyImage from './resources/history_1.JPG';
import goalImage from './resources/goal.jpeg';

import styles from './styles';

const Component = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Section id="about" color="text" title="Sobre" sx={styles.root}>
      <SideBySide
        color="secondary"
        title="História"
        image={historyImage}
        reverse
        sx={{ ...styles.history, ...(isDesktop ? styles.historyDesktop : {}) }}
      >
        <Typography>
          O sucesso do Ostras Lounge começa na qualidade dos produtos com que
          escolhe trabalhar. As nossas ostras são produzidas em águas nacionais
          e sujeitas a rigorosos testes de controlo de qualidade ao longo de
          todo o processo, recorrendo à mais avançada tecnologia que existe na
          área da aquacultura. Damos aos nossos clientes motivos para nunca
          esquecerem a experiência que lhes proporcionamos.
        </Typography>
      </SideBySide>
      {!isDesktop && <Divider orientation="horizontal" sx={styles.divider} />}
      <SideBySide color="secondary" title="Missão" image={goalImage}>
        <Typography>
          O Ostras Lounge quer servir as melhores ostras e tem como objetivo ser
          referência pela alta qualidade. A seleção do nosso produto é muito
          criteriosa, sendo a escolha do nosso fornecedor o ponto chave para o
          sucesso. A produção, depuração e sua distribuição são os fatores que
          determinam a escolha de um parceiro de topo nesta área.
        </Typography>
      </SideBySide>
    </Section>
  );
};

export default Component;
