import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles';

const Component = ({ sx, style, ...rest }, ref) => {
  return (
    <Box component="i" sx={{ ...styles.root, ...sx }} style={style} ref={ref}>
      <FontAwesomeIcon {...rest} />
    </Box>
  );
};

export default forwardRef(Component);
