import { cloneElement } from 'react';
import { Box } from '@mui/material';

import styles from './styles';

const Component = ({ on = false, onIcon, offIcon }) => {
  return (
    <Box component="div" sx={styles.root}>
      {cloneElement(onIcon, {
        sx: {
          ...styles.icon,
          ...styles.onIcon,
          ...(on ? styles.onIconShow : {})
        }
      })}
      {cloneElement(offIcon, {
        sx: {
          ...styles.icon,
          ...styles.offIcon,
          ...(!on ? styles.offIconShow : {})
        }
      })}
    </Box>
  );
};

export default Component;
