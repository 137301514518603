import { Box } from '@mui/material';

import Landing from './components/Landing';
import Overview from './components/Overview';
import Gallery from './components/Gallery';
import Reviews from './components/Reviews';
import ReserveNow from './components/ReserveNow';
import About from './components/About';
import Services from './components/Services';

const Page = () => {
  return (
    <Box component="div">
      <Landing />
      <Overview />
      <Gallery />
      <Reviews />
      <About />
      <Services />
      <ReserveNow />
    </Box>
  );
};

export default Page;
