import { Typography, useTheme, useMediaQuery, Divider } from '@mui/material';
import Section from 'components/Section';
import SideBySide from 'components/SideBySide';

import eventsImage from './resources/events.jpg';

import styles from './styles';

const Component = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Section id="services" color="text" title="Serviços" sx={styles.root}>
      <SideBySide
        color="secondary"
        title="Eventos"
        image={eventsImage}
        reverse
        sx={{ ...styles.events, ...(isDesktop ? styles.eventsDesktop : {}) }}
      >
        <Typography display="block">
        Complemente a sua festa/evento e proporcione uma experiência inesquecível aos seus amigos!
        </Typography>
        <Typography display="block">
        <br></br>
        Serviço inclui:
        </Typography>
        <Typography display="block">
          • Serviço de Abertura
        </Typography>
        <Typography display="block">
          • Ostras
          </Typography>
          <Typography display="block">
          • Deslocação gratuita em Setúbal
        </Typography>
        <br></br>
        <Typography display="block">
        Quantidade mínima sob consulta
        </Typography>
        <Typography display="block">
        Deslocação fora de Setúbal sob consulta
        </Typography>
        <Typography display="block">
        Peça informações!!!
        </Typography>
      </SideBySide>
      {/* {!isDesktop && <Divider orientation="horizontal" sx={styles.divider} />} */}
    </Section>
  );
};

export default Component;
